import { ADD_CART_ITEM, REMOVE_CART_ITEM, EDIT_CART_ITEM ,CLEAR_CART, SET_PICKUP_DATE, 
    SET_PICKUP_TIME, SET_SETTINGS, SET_TIMER, SET_GRATUITY,
SET_TAX_CATEGORIES, SET_MENU_ACTIVE, SET_NO_SLOT_RESERVATION } from './actionTypes';

let nextCartId = 0;

export const addCartItem = content => ({
    type: ADD_CART_ITEM,
    payload: {
        id: ++nextCartId,
        content
    }
})

export const editCartItem = content => ({
    type: EDIT_CART_ITEM,
    payload: {
        content
    }
})

export const removeCartItem = content => ({
    type: REMOVE_CART_ITEM,
    payload: {
        content
    }
})

export const clearCart = () => ({
    type: CLEAR_CART,
    payload: {}
})

export const setPickupDate = content => ({
    type: SET_PICKUP_DATE,
    payload: {
        content
    }
})

export const setPickupTime = content => ({
    type: SET_PICKUP_TIME,
    payload: {
        content
    }
})

export const setSettings = content => ({
    type: SET_SETTINGS,
    payload: {
        content
    }
})

export const setTimer = content => ({
    type: SET_TIMER,
    payload: {
        content
    }
})

export const setGratuity = content => ({
    type: SET_GRATUITY,
    payload: {
        content
    }
})


export const setTaxCatagories = content => ({
    type: SET_TAX_CATEGORIES,
    payload: {
        content
    }
})

export const setIsMenuActive = content => ({
    type: SET_MENU_ACTIVE,
    payload: {
        content
    }
})

export const setNoSlotReservation = content => ({
    type: SET_NO_SLOT_RESERVATION,
    payload: {
        content
    }
})
