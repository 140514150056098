import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import {
  getCartItems,
  getCartSubtotal,
  getCartTaxes,
  getGratuityAmount,
  getGratuityPercentage,
  isCartWeightMinimumValid,
  isCartWeightMaximumValid,
} from "../redux/selectors";
import { connect } from "react-redux";
import { formatPriceForTotal } from "../utilities";
import { TAX_RATE, API_URL } from "../constants";
import MaskedInput from "react-text-mask";

import axios from "axios";
import { Redirect } from "react-router-dom";
import Modal from "react-modal";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      color: "#4A4A4A",
      fontFamily:
        '"Source Sans Pro", -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#c6c6c6",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <div
      style={{
        padding: "0.8125em 0.625em 0.5625em",
        border: "1px solid #c6c6c6",
        borderRadius: "0.21875rem",
      }}
    >
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}

class Checkout extends React.Component {
  state = {
    clientSecret: "",
    name: {
      value: "",
      isValid: false,
      scrollTo: 'name'
    },
    email: {
      value: "",
      isValid: false,
      scrollTo: 'email'
    },
    confirmEmail: {
      value: "",
      isValid: false,
      scrollTo: 'email-confirm'
    },
    phoneNumber: {
      value: "",
      isValid: false,
      scrollTo: 'phone'
    },
    orderNickname: {
      value: "",
      isValid: false,
      scrollTo: 'order-nickname'
    },
    areTermsAccepted: {
      value: "",
      checked: false,
      isValid: false,
      scrollTo: 'accept-terms'
    },
    isFormSubmitted: false,
    isOrderProcessing: false,
    errorMessage: "",
    confirmationNumber: "",
    isTermsModalOpen: false,
  };

  isFormValid = () =>
    this.state.name.isValid &&
    this.state.email.isValid &&
    this.state.confirmEmail.isValid &&
    this.state.phoneNumber.isValid &&
    this.state.orderNickname.isValid &&
    this.state.areTermsAccepted.isValid;

  isSubmitButtonDisabled = () => !this.isFormValid() || this.state.isOrderProcessing

  handleChange(name, e) {
    let change = {};
    let value = e.target.value || e.target.checked;
    let isValid = false;
    change[name] = {};
    change[name].value = e.target.value;
    if (e.target.checked !== undefined) {
      change[name].checked = e.target.checked;
    }
    if (value || e.target.checked) {
      switch (name) {
        case "name":
          isValid = value.length > 0;
          break;
        case "email":
          isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
          break;
        case "confirmEmail":
          isValid = value === this.state.email.value;
          break;
        case "phoneNumber":
          isValid = value.match(/\d/g).length === 10;
          break;
        case "orderNickname":
          isValid = value.length > 0;
          break;
        case "areTermsAccepted":
          isValid = e.target.checked;
          break;
        default:
          break;
      }
    }
    change[name].isValid = isValid;
    this.setState(change);
  }

  componentDidMount = async () => {
    try {
      await axios.get(API_URL + "/cart/validate", {
        withCredentials: true,
      });
      const paymentIntentResponse = await axios.post(
        API_URL + "/payment/createPaymentIntent",
        {},
        {
          withCredentials: true,
        }
      );
      this.setState({
        clientSecret: paymentIntentResponse.data.clientSecret,
      });
    } catch (e) {
      window.location.replace("/review");
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    this.setState({
      isFormSubmitted: true,
      isOrderProcessing: true,
    });

    //Check if everything is valid
    if (!this.isFormValid()) {
      this.setState({
        isOrderProcessing: false,
      });
      const foundValue = Object.values(this.state).find((value) => value?.isValid === false);
      const scrollTarget = foundValue.scrollTo;
      const element = document.querySelector(`#${scrollTarget}`);
      if (element) {
        // Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        window.scrollTo(0,0)
      }
      return;
    }
    const { stripe, elements } = this.props;
    const fields = {
      name: this.state.name.value,
      nickname: this.state.orderNickname.value,
      email: this.state.email.value,
      phone_number: this.state.phoneNumber.value,
      special_requests: "",
    };

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      this.setState({
        isOrderProcessing: false,
      });
      return;
    }
    try {
      const resp = await axios.get(API_URL + "/cart/validate", {
        withCredentials: true,
      });
      if (!resp.data.valid && resp.data.message) {
        alert(resp.data.message);
        window.location.replace("/review");
      } else if (!resp.data.valid) {
        alert(
          "Your time has expired. Please select your date and try your order again. Your Card has not been charged."
        );
        window.location.replace("/");
        return;
      }
    } catch (e) {
      alert(e.data.message);
      window.location.replace("/review");
    }

    /**
     * Pre-payment, to save fields for webhook processing
     */
    let prePaymentResponse = await axios.post(
      API_URL + "/cart/pre-payment",
      {
        fields,
        client: navigator.userAgent,
      },
      {
        withCredentials: true,
      }
    );

    let confirmation_number = "";
    if (
      prePaymentResponse.data &&
      prePaymentResponse.data.confirmation_number
    ) {
      confirmation_number = prePaymentResponse.data.confirmation_number;
    }

    const result = await stripe.confirmCardPayment(this.state.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: this.state.name.value,
          email: this.state.email.value,
        },
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.error(result.error.message);
      this.setState({
        errorMessage: result.error.message,
        isOrderProcessing: false,
      });
      window.scrollTo(0, 0);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        // Check every x seconds until order is processed
        this.interval = setInterval(() => {
          axios
            .get(API_URL + "/cart", {
              withCredentials: true,
            })
            .then((response) => {
              let { data } = response;
              let { cart } = data;
              if (cart && cart.order_processed) {
                this.setState({
                  confirmationNumber: confirmation_number,
                  isOrderProcessing: false,
                });
                clearInterval(this.interval);
              }
            });
        }, 2000);
      }
    }
  };

  openModal = () => {
    document.body.style.overflow = "hidden";
    this.setState({ isTermsModalOpen: true });
  };

  closeModal = () => {
    document.body.style.overflow = "visible";
    this.setState({ isTermsModalOpen: false });
  };

  checkTerms = () => {
    this.setState({ areTermsAccepted: !this.state.areTermsAccepted });
  };

  render() {
    if (this.state.confirmationNumber) {
      return (
        <Redirect to={`/confirmation?id=${this.state.confirmationNumber}`} />
      );
    }
    const itemList = this.props.items.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            {item.qty} {item.quantitySpecifier} {item.title}
          </td>
          <td>{formatPriceForTotal(item.qty * item.price)}</td>
        </tr>
      );
    });

    const error = this.state.errorMessage ? (
      <div className="notice notice-alert">
        <strong>Alert:</strong> {this.state.errorMessage}
      </div>
    ) : (
      ""
    );

    const customStyles = {
      content: {
        top: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "40px",
        maxHeight: "60%",
        minWidth: "400px",
      },
    };

    return (
      <main>
        <section>
          <div className="page-container">
            <div className="content-container-800">
              {error}
              <div className="segment-intro">
                <h1 className="no-outline">Checkout</h1>
                <p>
                  If you have a gift card, please email us at{" "}
                  <a href="mailto:preorders@franklinbbq.com">
                    preorders@franklinbbq.com
                  </a>{" "}
                  with contact information.
                </p>
              </div>
            </div>
            <div className="content-container-700">
              <form
                onSubmit={this.handleSubmit}
                id="payment-form"
                className={`payment-form ${
                  this.state.isFormSubmitted ? "form-submitted" : ""
                }`}
                noValidate
              >
                <div className="form-space" style={{}}>
                  <h2 className="no-outline">Credit card information</h2>
                  <label htmlFor="name">Name on Card</label>
                  <input
                    id="name"
                    name="name"
                    autoComplete="name"
                    value={this.state.name.value}
                    placeholder="Name on Card"
                    required
                    type="text"
                    onChange={this.handleChange.bind(this, "name")}
                    className={`no-outline ${
                      this.state.name.isValid ? "" : "invalid"
                    }`}
                  />
                  <label>
                    Card Information
                    <CardSection />
                  </label>
                </div>
                <div className="form-space">
                  <h2>Confirmation information</h2>
                  <div className="split">
                    <div>
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        name="email"
                        value={this.state.email.value}
                        onChange={this.handleChange.bind(this, "email")}
                        pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                        placeholder="Email"
                        required
                        type="email"
                        className={`no-outline ${
                          this.state.email.isValid ? "" : "invalid"
                        }`}
                      />
                    </div>
                    <div>
                      <label htmlFor="email-confirm">Confirm email</label>
                      <input
                        id="email-confirm"
                        name="email-confirm"
                        value={this.state.confirmEmail.value}
                        onChange={this.handleChange.bind(this, "confirmEmail")}
                        placeholder="Confirm Email"
                        required
                        type="email"
                        className={`no-outline ${
                          this.state.confirmEmail.isValid ? "" : "invalid"
                        }`}
                      />
                    </div>
                  </div>
                  <label htmlFor="phone">Phone number</label>
                  <MaskedInput
                    mask={[
                      "(",
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    guide={false}
                    id="phone"
                    maxLength={14}
                    minLength={10}
                    value={this.state.phoneNumber.value}
                    onChange={this.handleChange.bind(this, "phoneNumber")}
                    name="phone"
                    phone="true"
                    placeholder="Phone number"
                    required
                    type="tel"
                    autoComplete="tel"
                    className={`no-outline ${
                      this.state.phoneNumber.isValid ? "" : "invalid"
                    }`}
                  />
                  <label htmlFor="order-nickname">Order nickname</label>
                  <input
                    id="order-nickname"
                    name="order-nickname"
                    placeholder="Order nickname"
                    required
                    type="text"
                    value={this.state.orderNickname.value}
                    onChange={this.handleChange.bind(this, "orderNickname")}
                    className={`no-outline ${
                      this.state.orderNickname.isValid ? "" : "invalid"
                    }`}
                  />
                </div>
                <div className="aside-detached">
                  <div className="order-summary">
                    <h3>Order</h3>
                    <table>
                      <tbody>{itemList}</tbody>
                    </table>
                    <h3>Estimated total</h3>
                    <table>
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>{formatPriceForTotal(this.props.subtotal)}</td>
                        </tr>
                        <tr>
                          <td>Sales tax {TAX_RATE * 100}%</td>
                          <td>{formatPriceForTotal(this.props.tax)}</td>
                        </tr>
                        <tr>
                          <td>
                            Gratuity {this.props.gratuityPercentage * 100}%
                          </td>
                          <td>
                            {formatPriceForTotal(this.props.gratuityAmount)}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td>
                            {formatPriceForTotal(
                              this.props.subtotal +
                                this.props.tax +
                                this.props.gratuityAmount
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="order-summary order-summary-split">
                  <div>
                    <h3>Payment information</h3>
                    <table>
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>{formatPriceForTotal(this.props.subtotal)}</td>
                        </tr>
                        <tr>
                          <td>Sales tax {TAX_RATE * 100}%</td>
                          <td>{formatPriceForTotal(this.props.tax)}</td>
                        </tr>
                        <tr>
                          <td>
                            Gratuity {this.props.gratuityPercentage * 100}%
                          </td>
                          <td>
                            {formatPriceForTotal(this.props.gratuityAmount)}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Due now</td>
                          <td>
                            {formatPriceForTotal(
                              this.props.subtotal +
                                this.props.tax +
                                this.props.gratuityAmount
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="terms-area">
                      <input
                        type="checkbox"
                        className={`${
                          this.state.areTermsAccepted.isValid ? "" : "invalid"
                        }`}
                        id="accept-terms"
                        name="accept-terms"
                        checked={this.state.areTermsAccepted.checked}
                        onChange={this.handleChange.bind(
                          this,
                          "areTermsAccepted"
                        )}
                      />
                      <label htmlFor="accept-terms">
                        {" "}
                        I agree to the{" "}
                        <button
                          type="button"
                          className="link"
                          onClick={this.openModal}
                        >
                          Franklin Barbecue cancellation policy
                        </button>
                      </label>
                    </div>
                    <button
                      disabled={this.state.isOrderProcessing}
                      className={`${this.isSubmitButtonDisabled() ? 'is_disabled' : ''} button button-large button-page`}
                      type="submit"
                    >
                      Submit payment
                    </button>
                    {this.state.isOrderProcessing ? (
                      <div className="overlay-authorizing">
                        <div className="spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div className="mobile-cta">
          <div className="fixed-area">
            <div className="page-container">
              <button
                disabled={this.state.isOrderProcessing}
                className={`${this.isSubmitButtonDisabled() ? 'is_disabled' : ''} button button-large button-page`}
                type="submit"
                form="payment-form"
              >
                Submit payment
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.isTermsModalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Franklin Barbecue Cancellation Policy"
        >
          <h2>Franklin Barbecue Cancellation Policy</h2>
          <p>
            Please note our 48 hour cancellation policy. If you cancel 48 hours
            or more before your pick-up date, then you will be refunded your
            payment less a 5% processing fee. Cancellation requests must be sent
            via email by replying to your confirmation email.
          </p>
          <p>
            If you do not show up to pick up your order or cancel your order
            less than 48 hours before your pick-up date,{" "}
            <strong>
              you will NOT be refunded any portion of the payment, and we do NOT
              reschedule “no shows”
            </strong>
            . All orders must be picked up on their scheduled pick up dates by
            1:45 p.m.
          </p>
          <p>Please note all orders are final.</p>
          <button type="button" onClick={this.closeModal}>
            close
          </button>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const items = getCartItems(state);
  const subtotal = getCartSubtotal(state);
  const tax = getCartTaxes(state);
  const gratuityPercentage = getGratuityPercentage(state);
  const gratuityAmount = getGratuityAmount(state);

  return {
    items,
    subtotal,
    tax,
    gratuityPercentage,
    gratuityAmount,
    isCartWeightMinimumValid: isCartWeightMinimumValid(state),
    isCartWeightMaximumValid: isCartWeightMaximumValid(state),
  };
};

export default connect(mapStateToProps)(Checkout);
