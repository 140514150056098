export let formatPrice = (price) => {
    try {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(price)
            .replace(/\D00(?=\D*$)/, '')
    } catch(e) {
        return 'NaN'
    }
}

export let formatPriceForTotal = (price) => {
    try {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(price)
    } catch(e) {
        return 'NaN'
    }
}

export let slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

export let createMarkup = (html) => {
    return { __html: html };
}

export const isProductSoldOut = (product, noSlotReservationRequired, isMeatSoldOut) => {
    if(product) {
        const doesHaveStockLimitations = !product.fields.ignoreSlotLimitations && noSlotReservationRequired
        const emptyStock = product.availability && product.availability.stock <= 0
        const soldOutCompare = doesHaveStockLimitations || emptyStock
        const isProductSausage = product.slug.includes('sausage');
        return soldOutCompare || (isMeatSoldOut && isProductSausage)
    }
}
