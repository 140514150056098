import React from 'react'
import { connect } from 'react-redux'
import { API_URL } from '../constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import axios from 'axios';

import {
    Redirect
  } from "react-router-dom"
import { getSettings } from '../redux/selectors'
import { setTimer, setPickupDate, setPickupTime, setNoSlotReservation, clearCart } from '../redux/actions'

// Add UTC Support
dayjs.extend(utc)

const NOT_SELECTED = '-1'

class DateSelector extends React.Component {
    state = {
        toMenu: false,
        data: [],
        chilledData: {},
        meatData: {},
        dataLoaded: false,
        selectedDay: NOT_SELECTED,
        selectedTime: NOT_SELECTED
    }

    dateMap = {}
    hidden = ''
    visibilityChange = ''
    chilledMessage = 'Whole Meats Only'

    handleVisibilityChange = () => {
        if (!document[this.hidden]) {
            this.getSlotInfo();
        }
    }

    getSlotInfo = () => {
        axios(API_URL + '/slots')
        .then(response => this.setState({
            data: response.data.slots ?? response.data,
            chilledData: response.data.chilledData ?? {},
            meatData: response.data.meatData ?? {},
            dataLoaded: true
        }))
    }

    componentDidMount() {
        this.dateMap = {}
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
        this.hidden = "hidden";
        this.visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
        this.hidden = "msHidden";
        this.visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
        this.hidden = "webkitHidden";
        this.visibilityChange = "webkitvisibilitychange";
        }
        // Handle page visibility change   
        document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
        this.getSlotInfo();
    }

    componentWillUnmount() {
        document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
    }

    selectDayEvent = (event) => {
        this.setState({
            selectedDay: event.target.value,
            selectedTime: NOT_SELECTED,
        })
    }

    selectTimeEvent = (event) => {
        this.setState({
            selectedTime: event.target.value,
        })
    }

    render() {
        if (this.state.toMenu === true) {
            return <Redirect to='/menu' />
        }
        let dayOptions = []
        if(this.state.dataLoaded) {
            //Build a map of day and times
            let chilledOnlyMap = {}
            const enableChilled = true;
            this.state.data.forEach(item => {
                this.dateMap[item.date] = []
                let chilledOnly = true;
                let timeSlotArray = []
                // Now, parse through the times
                item.timeSlots.forEach((timeSlot, index) => {
                    const {time, starting} = timeSlot
                    const cartUsed = item.carts[index];
                    const ordersUsed = item.orders[index];
                    // Check if we have an available slot (allow no slot reservation required)
                    if(item.lessThanOrderDate && enableChilled && this.state.chilledData[item.date]) {
                        timeSlotArray.push({
                            time,
                            msg: this.chilledMessage});
                    }
                    else if((starting - cartUsed - ordersUsed) > 0 && !item.lessThanOrderDate) {
                        //Add the time to the array
                        timeSlotArray.push({time});
                        // This isn't a chilled only date
                        chilledOnly = false;
                    } else if(enableChilled && this.state.chilledData[item.date]) {
                        timeSlotArray.push({
                            time,
                            msg: this.chilledMessage});
                    }
                })
                if(enableChilled && this.state.chilledData[item.date]) {
                    chilledOnlyMap[item.date] = chilledOnly
                }
                if((!enableChilled || !chilledOnly || (chilledOnly && this.state.chilledData[item.date])) && this.state.meatData[item.date]) {
                    // add this to the map
                    this.dateMap[item.date] = timeSlotArray
                }
            })
            dayOptions = this.state.data.filter(item => this.dateMap[item.date].length > 0).map((item, index) => {
                // UTC Date - 2020-09-15T00:00:00.000Z
                let dateObj = dayjs.utc(item.date)
                let dateString = dateObj.utc().format('ddd MMMM D')
                let chilledMessage = chilledOnlyMap[item.date] ? ` (${this.chilledMessage})` : ''
                let option = <option key={index} value={item.date}>{dateString}{chilledMessage}</option>
                return option
            })
        }
        let timeSelection = this.state.selectedDay !== NOT_SELECTED ? this.dateMap[this.state.selectedDay].map( (value, index) => {
            //Time format 13:00
            let [hour, minutes] = value.time.split(':')
            let dayObject = dayjs()
            dayObject = dayObject.hour(hour)
            dayObject = dayObject.minute(minutes)
            let interval = dayObject.add(this.props.settings.pickupTimeRange, 'minutes')
            let timeString = `${dayObject.format('hh:mm A')} - ${interval.format('hh:mm A')}`
            if(value.hasOwnProperty('msg')) {
                timeString += ` (${value.msg})`
            }
            return <option key={index} value={value.time}>{timeString} </option> 
        }) : ''
        return (
            <div className="date-selection">
                <div className="controls">
                    <div>
                        <label htmlFor="pick-up-day">
                            <h2>Day</h2>
                        </label>
                        <div className="styled-select quantity">
                            <select aria-label="Select a day to order" onChange={this.selectDayEvent} value={this.state.selectedDay} name="pick-up-day" id="pick-up-day">
                                <option value={NOT_SELECTED}>Select a day</option>
                                { dayOptions }
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="pick-up-time">
                            <h2>Time</h2>
                        </label>
                        <div className="styled-select quantity">
                            <select aria-label="Select a time to order" onChange={this.selectTimeEvent} value={this.state.selectedTime} name="pick-up-time" id="pick-up-time">
                                <option value={NOT_SELECTED}>Select a Time</option>
                                { timeSelection }                        
                            </select>
                        </div>
                    </div>
                </div>
                <button onClick={this.handleSubmit} className="button button-large button-page show-all">Select Date &amp; Time</button>
            </div>
        );
    }

    handleSubmit = () => {
        if(this.state.selectedDay !== NOT_SELECTED && this.state.selectedTime !== NOT_SELECTED) {
            //We have a valid date and time.
            axios.post(API_URL + '/cart', {
                date: this.state.selectedDay,
                time: this.state.selectedTime
            }, {
                withCredentials: true
              })
            .then(response => {
                let { data } = response
                let { cart, currentDate } = data
                // Set the timer, pickup date, and time
                this.props.clearCart()
                this.props.setTimer({
                    start: cart.checkout_timer.start,
                    end: cart.checkout_timer.end,
                    currentDate
                })
                this.props.setPickupDate(cart.reserved.date)
                this.props.setPickupTime(cart.reserved.time)
                this.props.setNoSlotReservation(cart.noSlotReservationRequired)
                this.setState(() => ({
                    toMenu: true
                }))
            })
            .catch(err => {
                window.alert(err)
                window.location.reload();
            })
        } else {
            alert('Please select a day and time')
        }
    }
  }

const mapStateToProps = state => {
    const settings = getSettings(state)
    return {
        settings
    }
}
  
  export default connect(mapStateToProps, { setTimer, setPickupDate, setPickupTime, setNoSlotReservation, clearCart })(DateSelector);
