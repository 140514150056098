export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const EDIT_CART_ITEM = "EDIT_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const CLEAR_CART = "CLEAR_CART";
export const SET_PICKUP_DATE = "SET_PICKUP_DATE";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_TIMER = "SET_TIMER";
export const SET_GRATUITY = "SET_GRATUITY";
export const SET_TAX_CATEGORIES = "SET_TAX_CATEGORIES";
export const SET_MENU_ACTIVE = "SET_MENU_ACTIVE";
export const SET_NO_SLOT_RESERVATION = "SET_NO_SLOT_RESERVATION";
