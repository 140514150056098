import React from 'react'
import { connect } from 'react-redux'
import { getCartItems, getCartSubtotal, getCartTaxes, 
    getGratuityAmount, getGratuityPercentage, isCartWeightMinimumValid, isCartWeightMaximumValid, getSettings, getCartWeight } from '../redux/selectors'
import { TAX_RATE } from '../constants'
import { setGratuity, removeCartItem, editCartItem } from "../redux/actions"
import { formatPriceForTotal, formatPrice } from '../utilities'
import {
    Link,
    Redirect
  } from "react-router-dom"
import axios from 'axios'
import { cloneDeep } from "lodash"
import { API_URL } from '../constants'

class Review extends React.Component {
    state = {
        toCheckout: false,
        showError: false,
        errorMessage: '',
        items: []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.items.length !== prevState.items.length) {
          return ({ items: cloneDeep(nextProps.items) }) // <- this is setState equivalent
        }
        return null
    }

    handleSubmit = () => {
        this.setState(() => ({
            toCheckout: true
        }))
    }

    removeItem(index, e) {
        // Get the item
        let item = this.props.items[index]
        axios.delete(`${API_URL}/cart/item?productSlug=${encodeURIComponent(item.productSlug)}&variantSku=${encodeURIComponent(item.variantSku)}`, {
            withCredentials: true
        })
        .then(response => {
            let { data } = response
            console.log(data)
            this.props.removeCartItem({index})
        })
        e.preventDefault()
    }

    gratuityChangeEvent = (event) => {
        axios.post(`${API_URL}/cart/gratuity`, {
            gratuity: parseFloat(event.target.value)
        }, {
            withCredentials: true
        }).then(response => {
            let { data } = response
            console.log(data)
            this.props.setGratuity(data.cart.gratuity)
        }).catch(err => {
            window.location.reload()
        })
    }

    onInputChange = (e, index) => {
        let items = this.state.items
        items[index].qty = e.target.value
        items[index].changed = true
        this.setState({items})
    }

    handleItemFormSubmit = (e, index) => {
        e.preventDefault()
        // Get the quantity of the item
        let items = this.state.items
        let {qty, productSlug, variantSku} = items[index]
        qty = Math.round(parseFloat(qty))
        if(Number.isNaN(qty) || qty <= 0) {
            axios.delete(`${API_URL}/cart/item?productSlug=${encodeURIComponent(productSlug)}&variantSku=${encodeURIComponent(variantSku)}`, {
                withCredentials: true
            })
            .then(response => {
                let { data } = response
                console.log(data)
                this.props.removeCartItem({index})
            })
        } else {
            axios.post(`${API_URL}/cart/item/update`, {
                productSlug,
                variantSku,
                qty
            }, {
                withCredentials: true
            }).then(response => {
                let { data } = response
                console.log(data)
                this.props.editCartItem({
                    index,
                    qty
                })
            }).catch(err => {
                if(err.response.data && err.response.data.error === 'stock empty' && 'qtyRemaining' in err.response.data) {
                    let qtyRemaining = err.response.data.qtyRemaining
                    window.alert(`You cannot add ${qty} to your cart. Only ${qtyRemaining} currently remain today.`)
                }
                else {
                    window.alert('This item does not have enough for your cart. Please try a smaller number.')
                }
            }).finally(() => {
                // Update the state after the items change
                this.setState({ items: cloneDeep(this.props.items) })
            })
        }
        
    }

    render() {
        if(!this.props.settings) {
            return null
        }
        if (this.state.toCheckout === true) {
            return <Redirect push to='/checkout' />
        }
        const itemList = this.state.items.map((item, index) => {
            return (
                <tr key={index} className={`product-row${item.changed ? ' amount_changed' : ''}`}>
                    <td>
                        <h2>{item.title}</h2>
                    </td>
                    <td>
                        <form onSubmit={e => this.handleItemFormSubmit(e, index)} className="product-selection product-selection-review" noValidate>
                            <div>
                                <input aria-label={`Change quantity of ${item.title}`} className="quantity-input focused-control" onChange={(e) => this.onInputChange(e, index)} type="number" step="1" min="0" max="30" 
                                pattern="\d*" autoComplete="off" value={item.qty} />
                                <label>{item.quantitySpecifier}</label>
                            </div> 
                            <button className="button button-small button-update" type="submit">Update</button>
                            <span>{formatPrice(item.price) } / {item.quantitySpecifier}</span>
                        </form>
                    </td>
                    <td><a aria-label={`Remove ${item.title}`} role="button" href="#removeItem" onClick={(e) => {this.removeItem(index,e)}} className="action-link">Remove</a></td>
                    <td>{formatPriceForTotal(this.props.items[index].qty * item.price)}</td>
                </tr>
            )
        })
        let error = ''
        let errorMessage = ''
        if(!this.props.isCartWeightMinimumValid) {
            errorMessage = `You must order at least ${this.props.settings.minPoundsPerOrder} lbs of meat`
        }
        else if(!this.props.isCartWeightMaximumValid) {
            errorMessage = `*TOO MUCH MEAT:* We're sorry, orders have a ${this.props.settings.maxPoundsPerOrder} lb weight limit. Please adjust your order.`
        }
        else {
            errorMessage = ''
        }
        if(this.props.items.length === 0) {
            errorMessage = <p>Your order is empty, <Link to="/menu">add something</Link> </p>
        } 
        if(errorMessage) {
            error = <div className="notice notice-alert no-outline"> <strong>Alert:</strong> {errorMessage}</div>
        }


        return (
            <React.Fragment>
                <section>
                    <div className="page-container">
                        <div className="content-container-700">
                            <div className="segment-intro">
                                <h1>Review order</h1>
                                <p className="no-outline"> We suggest at least 1/2–2/3 lbs. of meat per person. Healthy eaters will eat
                                much more. We provide sauce, pickles, onions and white bread with your order. We are unable to wrap
                    different increments of meat separately. </p>
                                <div className="link-back">&lt; <Link to="/menu" className="black-underline">Go back to Menu</Link>
                                </div>
                            </div>
                        </div>
                        <div className="content-container-700 no-outline">
                            <div className="full-order">
                                {error}
                                <table>
                                    <tbody>
                                        {itemList}
                                    </tbody>
                                </table>
                            </div>
                            <div className="order-summary order-summary-standalone">
                                <h3>Estimated total</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td>{formatPriceForTotal(this.props.subtotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>Sales tax {TAX_RATE*100}%</td>
                                            <td>{formatPriceForTotal(this.props.tax)}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="gratuity-area">
                                                    <span>Gratuity</span>
                                                    <div className="styled-select gratuity-select"> 
                                                        <select onChange={(e) => this.gratuityChangeEvent(e)} value={this.props.gratuityPercentage} id="gratuity">
                                                            <option value="0.2">20%</option>
                                                            <option value="0.15">15%</option>
                                                            <option value="0.1">10%</option>
                                                        </select> 
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{formatPriceForTotal(this.props.gratuityAmount)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td />
                                            <td>{formatPriceForTotal(this.props.subtotal + this.props.tax + this.props.gratuityAmount)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <button disabled={errorMessage} className={`${errorMessage ? 'is_disabled' : ''} button button-large button-page`} onClick={this.handleSubmit}>Checkout</button>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="mobile-cta">
                    <div className="fixed-area">
                        <div className="page-container">
                            <button disabled={errorMessage} className={`${errorMessage ? 'is_disabled' : ''} button button-large button-page`} onClick={this.handleSubmit}>Checkout</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const items = getCartItems(state)
    const subtotal = getCartSubtotal(state)
    const gratuityPercentage = getGratuityPercentage(state)
    const gratuityAmount = getGratuityAmount(state)
    const settings = getSettings(state)
    const cartWeight = getCartWeight(state)
    const tax = getCartTaxes(state)

    return {
        items,
        subtotal,
        tax,
        gratuityPercentage,
        gratuityAmount,
        settings,
        cartWeight,
        isCartWeightMinimumValid: isCartWeightMinimumValid(state),
        isCartWeightMaximumValid: isCartWeightMaximumValid(state)
    }
}

export default connect(mapStateToProps, { setGratuity, removeCartItem, editCartItem })(Review)