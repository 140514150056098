import React from 'react';
import MenuItem from './MenuItem'
import { connect } from "react-redux"
import { getCartItems, getCartNoSlotReservation } from '../redux/selectors'
import { slugify, createMarkup, isProductSoldOut } from '../utilities'

class MenuSection extends React.Component {

    render() {
        let subtitle
        if (this.props.items.fields.subtitle) {
            subtitle = <div className="subtitle" dangerouslySetInnerHTML={createMarkup(this.props.items.fields.subtitle)} />
        }
        return (
            <section id={slugify(this.props.items.title)}>
                <div className="page-container">
                    <div className="content-container-800">
                        <div className="segment-intro segment-intro-tighter">
                            <h1>{this.props.items.title}</h1>
                            {subtitle}
                        </div>
                    </div>
                    <ul className="menu-blocks">
                        {
                            this.props.items.fields.menuGroup.map(item => {
                                let product = this.props.products[item.slug]
                                let meetsCondition = false
                                if (product && product.status === 'live') {
                                    const isInCart = this.props.cartItems.some(cartItem => cartItem.productSlug === product.slug)
                                    const doesHaveStockLimitations = !product.fields.ignoreSlotLimitations && this.props.noSlotReservationRequired
                                    meetsCondition = isInCart || (product.availability && product.availability.stock < 5) || doesHaveStockLimitations || isProductSoldOut(product, this.props.noSlotReservationRequired, this.props.isMeatSoldOut)
                                    return (<li className={`menu-block menu-block-product${meetsCondition ? ' selection_added' : ''}`} key={item.id}>
                                        { Object.keys(this.props.products).length > 0 ? <MenuItem item={item} onMenuItemAdded={this.props.onMenuItemAdded} product={product} isMeatSoldOut={this.props.isMeatSoldOut} /> : null}
                                     </li>)
                                }
                                return null;
                            }).filter(item => item)
                        }
                        <li key="998" className="menu-block menu-block-blank"></li>
                        <li key="999" className="menu-block menu-block-blank"></li>
                    </ul>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    const cartItems = getCartItems(state)
    const noSlotReservationRequired = getCartNoSlotReservation(state)
    return {
        cartItems,
        noSlotReservationRequired
    }
}

export default connect(mapStateToProps)(MenuSection)
