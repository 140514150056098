import React from 'react'
import { connect } from "react-redux"
import { addCartItem } from "../redux/actions"
import axios from 'axios'
import { API_URL } from '../constants'


class VariantItem extends React.Component {
    state = {
        selectedIndex: 0,
        qty: ''
    }

    componentDidMount() {
        // Figure out which variant is the default, then set it
        if(this.props.variants.length > 1) {
            //Find the selected option
            let index = this.props.variants.findIndex(variant => 
                variant.attributes.isDefault
            )
            this.setState({selectedIndex: index})
            this.props.onVariantSelected(index)
        }
    }


    selectChangeEvent = (event) => {
        this.setState({
            selectedIndex: event.target.value,
            qty: '',
        })
        this.props.onVariantSelected(event.target.value)
    }

    handleQuantityUpdate = (event) => {
        this.setState({qty: event.target.value})
    }

    handleSubmit = (event) => {
        const selectedVariantIndex = this.state.selectedIndex
        let selectedVariant = this.props.variants[selectedVariantIndex]
        const qty = Math.round(parseFloat(this.state.qty))
        axios.post(`${API_URL}/cart/item`, {
            qty,
            productSlug: this.props.product.slug,
            variantSku: selectedVariant.attributes.sku,
            noSlotReservationRequired: this.props.product.fields.ignoreSlotLimitations ? this.props.product.fields.ignoreSlotLimitations : false
        }, {
            withCredentials: true
        }).then(response => {
            //let { data } = response
            this.props.addCartItem({
                quantitySpecifier: selectedVariant.fields.quantitySpecifier[0],
                productSlug: this.props.product.slug,
                variantSku: selectedVariant.attributes.sku,
                title: this.props.title,
                price: parseFloat(selectedVariant.attributes.price),
                qty,
                weight: selectedVariant.attributes.weight ? parseFloat(selectedVariant.attributes.weight) : 0,
                tax_id: this.props.product.tax_id,
                ignoreMiniumWeightRequirements: this.props.product.fields.ignoreMiniumWeightRequirements 
            });
            this.setState({
                qty: ''
            })
            this.props.onMenuItemAdded()
        }).catch(err => {
            if(err.response.data && err.response.data.error === 'stock empty') {
                window.alert(`You cannot add ${parseFloat(this.state.qty)} to your cart. Only ${parseFloat(this.props.product.availability.stock)} currently remain today.`)
                this.props.onMenuItemAdded()
            }
        })
        event.preventDefault()
    }
    
    render() {
        let selectorVariant = (this.props.variants && this.props.variants.length === 1) ? 
            <label>{this.props.variants[0].fields.quantitySpecifier[0]}</label> :
            <div className="styled-select">         
                <select onChange={this.selectChangeEvent} value={this.state.selectedIndex}>
                    {this.props.variants.map((variant, index) => 
                        (variant.attributes.enabled !== '0' && variant.attributes.enabled !== false && variant.attributes.enabled !== '') ? <option key={index} value={index}>{variant.fields.quantitySpecifier[0] }</option> : ''
                    )}
                </select>
            </div>
        return (
            <form className="product-selection" onSubmit={this.handleSubmit}>
                {this.props.isSoldOut ? '' : <input autoComplete="off" aria-label={`Pick a number for ${this.props.title}`} value={this.state.qty} onChange={this.handleQuantityUpdate} 
                className="quantity-input focused-control" max="25" min="0" name="quantity" step="1" type="number" pattern="\d*"/>}
                {this.props.isSoldOut ? <label></label> : selectorVariant }
                <button disabled={this.props.isSoldOut} className={`button button-product ${this.props.isSoldOut? 'is_disabled' : ''}`} type="submit" value="submit">Add</button> 
            </form>
        )
    }
}

export default connect(
    null,
    { addCartItem }
)(VariantItem)
