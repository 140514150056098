import React from 'react'
import { connect } from 'react-redux'
import { getPickupDate, getPickupTime, getSettings } from '../redux/selectors'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { clearCart } from '../redux/actions'
import { API_URL } from '../constants'
import axios from 'axios'

import {
    Redirect
  } from "react-router-dom"

// Add UTC Support
dayjs.extend(utc)

class Pickup extends React.Component {
    state = {
        toHome: false
    }

    handleCartReset = async () => {
        try {
            await axios.delete(`${API_URL}/cart`, {
                withCredentials: true
            })
            this.props.clearCart()
            this.setState({toHome: true})
        } catch (err) {
            alert('There was an issue clearing your cart. Please try again')
        }
    }
    render() {
        if(this.state.toHome) {
            return <Redirect to='/' />
        }
        let pickupString = '';
        if(this.props.pickupDate && this.props.pickupTime) {
            let dateObj = dayjs.utc(this.props.pickupDate)
            let dateString = dateObj.utc().format('ddd MMMM D')

            let [hour, minutes] = this.props.pickupTime.split(':')
            let dayObject = dayjs()
            dayObject = dayObject.hour(hour)
            dayObject = dayObject.minute(minutes)
            let interval = dayObject.add(this.props.settings.pickupTimeRange, 'minutes')
            pickupString = 
                <div style={{display: 'flex'}}>
                    <span>
                    Pick up date: <em>{dateString}</em> / <em>{dayObject.format('hh:mm A')} - {interval.format('hh:mm A')}</em>
                    </span>
                    <button className="black-underline link" onClick={this.handleCartReset} style={{paddingLeft: '10px', paddingRight: '10px'}}>Change Date / Start Over</button>
                </div>
        }
        return (pickupString)
    }
}

const mapStateToProps = state => {
    const pickupDate = getPickupDate(state)
    const pickupTime = getPickupTime(state)
    const settings = getSettings(state)

    return {
        pickupDate,
        pickupTime,
        settings
    }
}





export default connect(
    mapStateToProps, { clearCart }
)(Pickup)