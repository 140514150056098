import { SET_SETTINGS, SET_MENU_ACTIVE } from "../actionTypes"

const initState =  {
    settings: {},
    loaded: false,
    isMenuActive: false
}

export default function reducer(state = initState, action) {
    switch(action.type) {
        case SET_SETTINGS: {
            const {content} = action.payload
            return {
                ...state,
                settings: { ...content},
                loaded: true
            }
        }
        case SET_MENU_ACTIVE: {
            const {content} = action.payload
            return {
                ...state,
                isMenuActive: content
            }
        }
        default:
            return state;
    }
}