import React from 'react';
import {
  Link
} from "react-router-dom"
import { connect } from 'react-redux'
import { getSettings } from './redux/selectors'

class Banner extends React.Component  {
  render() {
    return (
      <section>
          <div className="page-container">
            <div className="hero" style={{backgroundImage: 'url("/assets/images/hero-trailer.jpg")'}}>
              <div className="flex-container">
                <h1>Place your order for pickup</h1>
                <ul>
                  <li style={{backgroundImage: 'url("/assets/images/ico-calendar.svg")'}}>Orders become available {this.props.settings.maxOrderTime} days in advance.</li>
                  <li style={{backgroundImage: 'url("/assets/images/ico-scales.svg")'}}>Minimum order of {this.props.settings.minPoundsPerOrder} pounds, up to {this.props.settings.maxPoundsPerOrder} pounds of meat.</li>
                </ul>
              </div>
              <div className="flex-container">
                <Link className="button button-large button-product" to="/information">More Info</Link>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

const mapStateToProps = state => {
  const settings = getSettings(state)
  return {
    settings
  }
}

export default connect(mapStateToProps)(Banner);
