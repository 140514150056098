import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer> 	
                <div className="page-container">
                    <div className="legal" style={{outline: 'none'}}> 			
                        <Link to="/privacy" style={{outline: 'none'}}>Privacy Policy</Link> | <Link to="/terms">Terms &amp; Conditions</Link>
                    </div> 	
                </div>
            </footer>
        )
    }
}

export default Footer