import {ADD_CART_ITEM, REMOVE_CART_ITEM, CLEAR_CART, SET_PICKUP_DATE, SET_PICKUP_TIME, 
    SET_TIMER, SET_GRATUITY, SET_TAX_CATEGORIES, EDIT_CART_ITEM, SET_NO_SLOT_RESERVATION} from "../actionTypes"
import { cloneDeep } from "lodash"

const initState =  {
    items: [],
    pickup: {
        date: '',
        time: null
    },
    timer: {
        start: 0,
        end: 0,
        currentDate: new Date()
    },
    gratuity: 0.2,
    noSlotReservationRequired: false,
    taxCategories: []
}

const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
}

const MAX_QUANTITY = 999;

export default function reducer(state = initState, action) {
    switch(action.type) {
        case ADD_CART_ITEM: {
            const {content} = action.payload
            let itemsCopy = cloneDeep(state.items)
            let productFound = false
            let cartItems = itemsCopy.map(item => {
                if(item.variantSku === content.variantSku) {
                    item.qty = clamp(item.qty + content.qty, 0, MAX_QUANTITY)
                    productFound = true
                }
                return item
            })
            if(!productFound) {
                cartItems.push(content)
            }
            return {
                ...state,
                items: cartItems
            }
        }
        case EDIT_CART_ITEM: {
            let items = cloneDeep(state.items)
            const {content} = action.payload
            const {index, qty} = content
            items[index].qty = qty
            return {
                ...state,
                items
            }
        }
        case REMOVE_CART_ITEM: {
            let items = cloneDeep(state.items)
            const {content} = action.payload
            const {index} = content
            items.splice(index, 1)
            return {
                ...state,
                items
            }
        }
        case CLEAR_CART: {
            const {items, pickup, timer, gratuity} = initState
            return {
                ...state,
                items,
                pickup,
                timer,
                gratuity
            }
        }
        case SET_PICKUP_DATE: {
            const {content} = action.payload
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    date: content,
                }
            }
        }
        case SET_PICKUP_TIME: {
            const {content} = action.payload
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    time: content
                }
            }
        }
        case SET_TIMER: {
            const {content} = action.payload
            const {start, end, currentDate} = content
            return {
                ...state,
                timer: {
                    ...state.timer,
                    start,
                    end,
                    currentDate
                }
            }
        }
        case SET_GRATUITY: {
            const {content} = action.payload
            return {
                ...state,
                gratuity: content
            }
        }
        case SET_TAX_CATEGORIES: {
            const {content} = action.payload
            return {
                ...state,
                taxCategories: cloneDeep(content)
            }
        }
        case SET_NO_SLOT_RESERVATION: {
            const {content} = action.payload
            return {
                ...state,
                noSlotReservationRequired: content
            }
        }
        default:
            return state;
    }
}