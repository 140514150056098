import React from 'react'
import MenuSection from '../menu-section/MenuSection'
import Cart from '../cart/Cart'
import { API_URL } from '../constants'
import axios from 'axios'
import {connect} from 'react-redux'
import { getPickupDate, getCartItems, getCartNoSlotReservation } from '../redux/selectors'
import { setIsMenuActive } from '../redux/actions'
import { Redirect, withRouter } from 'react-router-dom'
import { slugify } from '../utilities'
import { Link, Events } from 'react-scroll'
import SiteBanner from '../site-banner/SiteBanner';

class Menu extends React.Component {
  state = {
    menu: [],
    products: {},
    carts: [],
    orders: [],
    menuItemAddedDate: null,
    goToReview: false,
    isMeatSoldOut: false
  }

  updateMenuData = async () => {
    const menuResponse = await axios.get(API_URL + '/menu')
    const productsResponse = await axios.get(API_URL + '/products',  {
          params: {
            date: this.props.pickupDate
          }
    })
    //Get the meat category
    let meatCategories = menuResponse.data.filter(item => item.title.toLowerCase().includes('meat'))
    let realCategories = meatCategories.reduce((acc, current) => [...acc, ...current.fields.menuGroup]
    ,[])
    .reduce((acc, current) => [...acc, current.slug], [])
    // Exclude the sasauge from the list
    .filter(item => !item.includes('sausage'))
    let meatStock = {};
    const productsData = productsResponse.data;
    let products = {}
    //Set the used on the products
    let productsArray = productsData.products.map(product => {
      const slug = product.slug
      if(product.availability) {
        let stock = product.availability.stock
        let orderUsed = productsData.orders.reduce((acc, order) => {
            let used = 0
            if(order.slug === slug) {
              used = order.used
            }
            return acc + used
          }, 0)
        let cartUsed = productsData.carts.reduce((acc, cart) => {
            let used = 0
            if(cart.slug === slug) {
              used = cart.used
            }
            return acc + used
        }, 0)
        let availableStock = (stock - orderUsed - cartUsed);
        const doesHaveStockLimitations = !product.fields.ignoreSlotLimitations && this.props.noSlotReservationRequired
        const emptyStock = product.availability && availableStock <= 0
        const soldOutCompare = doesHaveStockLimitations || emptyStock
        if(realCategories.includes(slug)) {
          meatStock[slug] = soldOutCompare
        }
        return {
          ...product,
          availability: {
            stock: availableStock
          },
          isSoldOut: soldOutCompare
        }
      } else {
        // We don't have any avaliability restrictions
        if(realCategories.includes(slug)) {
          meatStock[slug] = true
        }
      }
      return product
    })
    // Check if meat is not sold out
    const meatValues = Object.values(meatStock)
    let isMeatSoldOut = meatValues.every((val) => val)
    //Set the data up as an object for fast lookup
    for(let product of productsArray) {
      products[product.slug] = { ...product }
    }
    return this.setState({
      menu: menuResponse.data,
      products,
      orders: productsData.orders,
      carts: productsData.carts,
      isMeatSoldOut
    })
  }

  onMenuItemAdded = () => this.setState({
    menuItemAddedDate: new Date()
  })

  async componentDidMount() {
    await this.updateMenuData();
    this.props.setIsMenuActive(true)
    Events.scrollEvent.register('begin', function(to, element) {
      // Hide the menu when scrolling starts
      document.body.classList.remove('show_nav_menu');
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if(prevState.menuItemAddedDate !== this.state.menuItemAddedDate) {
      // Update the menu
      await this.updateMenuData();
    }
  }

  onReviewClick = () => {
    this.props.setIsMenuActive(false)
    this.setState({
      goToReview: true
    })
  }
 
  render() {
    if(this.state.goToReview) {
      return <Redirect push to="/review" />
    }
    let menuData = this.state.menu.map((item, index) => <li key={index}><Link activeClass="active" to={slugify(item.title)} spy={true} smooth={true} offset={-50} duration={700}>{item.title}</Link></li>)
    return (
      <React.Fragment>
          <nav>
            <div className="page-container">
              <ul>
                { menuData }
              </ul>
              <ul>
                <li><a href="http://franklinbarbecue.com/">Franklinbbq.com</a></li>
                <li><a href="https://shop.franklinbbq.com">Shop</a></li>
              </ul>
            </div>
        </nav>
        <main>
          <div className="page-container">
            <SiteBanner />
          </div>
          {this.state.menu.map((item, index) => <MenuSection key={index} items={item} onMenuItemAdded={this.onMenuItemAdded} products={this.state.products} isMeatSoldOut={this.state.isMeatSoldOut} />)}
        </main>
        <Cart onCartItemRemoved={this.onMenuItemAdded} />
        <div className="mobile-cta">
          <div className="fixed-area">
            <div className="page-container">
              <button onClick={this.onReviewClick} className={`${this.props.cartItems.length === 0 ? 'is_disabled' : ''} button button-large button-page`}>Review order</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const pickupDate = getPickupDate(state)
  const cartItems = getCartItems(state)
  const noSlotReservationRequired = getCartNoSlotReservation(state)
  return {
    pickupDate,
    cartItems,
    noSlotReservationRequired
  }
}

export default withRouter(connect(mapStateToProps, { setIsMenuActive })(Menu));
