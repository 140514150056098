import React from 'react';
import Banner from '../Banner';
import DateSelector from '../date-selector/DateSelector';
import SiteBanner from '../site-banner/SiteBanner';

function Home() {
    return (
      <React.Fragment>
        <Banner />
        <section className="main-section">
            <div className="content-container-800">
                <div className="page-container">
                  <SiteBanner noMargin={true} />
                  <p style={{marginTop: '1em' }}>
                      Pick a date, place your order, pay in full and you will be able to check in to pick up your order on the date and time window of your choosing. Your order and date are not confirmed until you place your order.
                  </p>
                  <DateSelector />
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
  
  export default Home
  