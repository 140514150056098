//The Tax Rate of the System
const stripeKey = {
    test: 'pk_test_BR7fn1gw9ZItpRdc1t77YQOV',
    live: 'pk_live_sBa5afG0ItBbh7bsT1HdirLp'
}

const dev = {
    API_URL:'http://localhost:3010/api/v2',
    STRIPE_KEY: stripeKey.test
}

const staging = {
    API_URL:'/api/v2',
    STRIPE_KEY: stripeKey.test
}

const production = {
    API_URL:'/api/v2',
    STRIPE_KEY: stripeKey.live
}

let config = dev

switch(process.env.REACT_APP_STAGE) {
    case 'staging':
        config = staging
        break
    case 'production':
        config = production
        break
    default:
        break

}

export const TAX_RATE = 0.0825
export const API_URL = config.API_URL
export const STRIPE_KEY = config.STRIPE_KEY
export const GA_ID = "G-K5DN279Y4V"
