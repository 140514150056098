import React from 'react'
import { API_URL, TAX_RATE } from '../constants'
import axios from 'axios'
import { connect } from 'react-redux'
import { getSettings } from '../redux/selectors'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';
import { formatPriceForTotal, createMarkup } from '../utilities'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { clearCart } from '../redux/actions'

// Add UTC Support
dayjs.extend(utc)

class Confirmation extends React.Component {
    state = {
        order: {}
    }

    componentDidMount() {
        this.props.clearCart()
        let params = queryString.parse(this.props.location.search)
        axios.get(API_URL + '/order/' + params.id)
            .then(response => {
                this.setState({
                    order: response.data.order
                })
                console.log(response)
            })
    }

    render() {
        if (this.state.order && this.state.order.items && this.props.settings) {
            const status = this.state.order.status === 'cancelled' ? <span>Cancelled</span> : <span>Confirmation</span>
            const itemList = this.state.order.items.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.qty} {item.quantitySpecifier} {item.name}</td>
                        <td>{formatPriceForTotal(item.qty * item.price)}</td>
                    </tr>
                )
            })

            let dateObj = dayjs.utc(this.state.order.pickup.date)
            let dateString = dateObj.utc().format('dddd MMMM D')
            let [hour, minutes] = this.state.order.pickup.time.split(':')
            let dayObject = dayjs()
            dayObject = dayObject.hour(hour)
            dayObject = dayObject.minute(minutes)
            let interval = dayObject.add(this.props.settings.pickupTimeRange, 'minutes')

            return (
                <React.Fragment>
                    <main>
                        <section>
                            <div className="page-container">
                                <div className="content-container-700">
                                    <div className="segment-intro">
                                        <h1 className={this.state.order.status === 'cancelled' ? 'cancelled' : ''}>
                                            {status}
                                        </h1>
                                    </div>
                                    <div className="notice notice-product notice-feature">
                                        <div>
                                            <h4>Check #</h4>
                                            <h2>{this.state.order.pickup_number}</h2>
                                        </div>
                                        <div>
                                            <h4>Pick up date:</h4>
                                            <h3>{dateString}</h3>
                                            <h2>{dayObject.format('hh:mm A')} - {interval.format('hh:mm A')}</h2>
                                        </div>
                                        <div>
                                            <h4>Order Nickname:</h4>
                                            <h3>{this.state.order.fields.nickname}</h3>
                                        </div>
                                        <div>
                                            <h4>Confirmation number:</h4>
                                            <h2>{this.state.order.confirmation_number}</h2>
                                        </div>
                                    </div>
                                    <span dangerouslySetInnerHTML={createMarkup(this.props.settings.confirmationPageText)}></span>
                                </div>
                            </div>
                        </section>
                    </main>
                    <aside>
                        <div className="order-summary">
                            <h3>Order</h3>
                            <table>
                                <tbody>
                                    {itemList}
                                </tbody>
                            </table>
                            <h3>Payment information</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>{formatPriceForTotal(this.state.order.money.subtotal)}</td>
                                    </tr>
                                    <tr>
                                        <td>Sales tax {TAX_RATE * 100}%</td>
                                        <td>{formatPriceForTotal(this.state.order.money.tax)}</td>
                                    </tr>
                                    <tr>
                                        <td>Gratuity {this.state.order.money.gratuity.percent * 100}%</td>
                                        <td>{formatPriceForTotal(this.state.order.money.gratuity.amount)}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td>{formatPriceForTotal(this.state.order.money.amount_paid)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </aside>
                </React.Fragment>
            )
        } else {
            return ''
        }
    }
}

const mapStateToProps = state => {
    const settings = getSettings(state)
    return {
        settings
    }
}

export default connect(mapStateToProps, { clearCart })(withRouter(Confirmation));
