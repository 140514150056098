import React, { Fragment } from 'react';
import VariantItem from './VariantItem';
import { formatPrice, createMarkup, isProductSoldOut } from '../utilities'
import { connect } from "react-redux"
import { getCartItems, getCartNoSlotReservation} from '../redux/selectors'

class MenuItem extends React.Component {
    state = {
        price: 0,
        selectedVariant: 0,
        isSoldOut: false
    }

    onVariantSelected = (value) => {
        this.setState({selectedVariant: value})
    }


    componentDidMount() {
        if(this.props.product) {
            this.setState({...this.state, isSoldOut: isProductSoldOut(this.props.product, this.props.noSlotReservationRequired, this.props.isMeatSoldOut)})
        }
    }


    render() {
        let styleBlock = {
            outline: 'none',
        }
        // Get the image out of the nested data
        let imageArr = this.props.product.fields.image
        if(imageArr && imageArr.length > 0) {
            styleBlock.backgroundImage = `url('${imageArr[0]}')`
        }

        let currentVariant = this.props.product.variants[this.state.selectedVariant]
        let quantitySpecifierSubtitle = currentVariant.fields.priceSubtitile
        let currentPrice = currentVariant.attributes.price
        let variantList = this.props.cartItems.filter(item => item.productSlug === this.props.product.slug)
        let addedVariants = variantList.reduce((acc, item, index) => {
            if(index > 0) {
                acc += ", "
            }
            acc += `${item.qty} ${item.quantitySpecifier}`
            return acc
        }, '')
        const qtySpecifier = currentVariant.fields.quantitySpecifier[0]

        // Format the price into a useful format
        let price = formatPrice(currentPrice)
        let messageString
        if(addedVariants) {
            messageString = `${addedVariants} in cart`
        }
        else if(this.state.isSoldOut) {
            messageString = 'Sold out on selected day'
        }
        else if(this.props.product.availability && this.props.product.availability.stock > 0 && this.props.product.availability.stock < 5) {
            messageString = `Less than 5 ${qtySpecifier} remaining`
        }
        return (
            <Fragment>
                <figure style={styleBlock} className={`${this.state.isSoldOut ? 'sold-out' : ''}`}>     
                    <div className="item-amount"> 
                            <span className="item-value">
                                <span className="display display-next">0</span>
                                <span className="display display-current">{messageString}</span>
                                <span className="display display-previous">0</span>
                                <span className="placeholder">0</span>
                            </span>         
                    </div>
                </figure>
                <div className="content content-mid">
                    <label>
                        <div className="product-details">
                            <div className="description">
                                <h2>{this.props.product.title}</h2>
                                <div className="subtitle" dangerouslySetInnerHTML={createMarkup(this.props.product.fields.subtitle)} />
                            </div>
                            <div className="pricing">
                                <h2>{price}</h2>
                                <p>{quantitySpecifierSubtitle}</p>
                            </div>
                        </div>
                        <VariantItem isSoldOut={this.state.isSoldOut} title={this.props.product.title} onMenuItemAdded={this.props.onMenuItemAdded} product={this.props.product} variants={this.props.product.variants} onVariantSelected={this.onVariantSelected}/>
                    </label>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const cartItems = getCartItems(state)
    const noSlotReservationRequired = getCartNoSlotReservation(state)
    return {
        cartItems,
        noSlotReservationRequired
    }
}
  

export default connect(mapStateToProps)(MenuItem)
