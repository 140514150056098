import React from 'react';
import {
  Router,
  Switch,
  Route,
  Link, Redirect
} from "react-router-dom"
import { createBrowserHistory } from "history";
import { wrapHistory } from "oaf-react-router";
import { connect } from 'react-redux';
import {
  setSettings, setPickupDate, setPickupTime,
  setTimer, setGratuity, setTaxCatagories, addCartItem, setNoSlotReservation
} from './redux/actions'
import { API_URL, STRIPE_KEY, GA_ID } from './constants'
import logo from './images/logo_v2.svg'

import Home from './home/Home';
import Menu from './menu/Menu';
import Terms from './legal/terms/Terms';
import PrivacyPolicy from './legal/privacy-policy/PrivacyPolicy';
import Footer from './footer/footer';
import Review from './review/Review';
import Checkout from './checkout/Checkout';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Pickup from './pickup/Pickup';
import Timer from './timer/Timer';
import { getTimer, getCartItems, isMenuActive } from './redux/selectors'
import axios from 'axios';
import Confirmation from './confirmation/Confirmation';
import Information from './information/Information';
import ReactGA from 'react-ga4';

//Franklin Stripe Key
const stripePromise = loadStripe(STRIPE_KEY);

const history = createBrowserHistory(); // or createHashHistory()
wrapHistory(history);

const trackingId = GA_ID; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
});

class App extends React.Component {

  state = {
    settingsLoaded: false,
    taxLoaded: false,
    cartLoaded: false
  }
  componentDidMount() {
    // Get the settings from the server
    axios(API_URL + '/settings')
      .then(response => this.props.setSettings(response.data))
      .finally(() => {
        this.setState({
          settingsLoaded: true
        })
      })
    axios(API_URL + '/tax-categories')
      .then(response => this.props.setTaxCatagories(response.data))
      .finally(() => {
        this.setState({
          taxLoaded: true
        })
      })

    axios.get(API_URL + '/cart', {
      withCredentials: true
    })
      .then(response => {
        let { data } = response
        let { cart, currentDate } = data
        if (cart && cart.checkout_timer.end > currentDate) {
          // Timer is still valid
          // Set the timer, pickup date, and time
          this.props.setTimer({
            start: cart.checkout_timer.start,
            end: cart.checkout_timer.end,
            currentDate
          })
          this.props.setPickupDate(cart.reserved.date)
          this.props.setPickupTime(cart.reserved.time)
          this.props.setGratuity(cart.gratuity)
          this.props.setNoSlotReservation(cart.noSlotReservationRequired)

          // Figure out all the items
          const cartItems = cart.items.map((item, index) => {
            // Build the item to add to cart
            let product = cart.products[index]
            let selectedVariant = product.variants.find(variant =>
              variant.attributes.sku === item.variant_sku)
            return {
              quantitySpecifier: selectedVariant.fields.quantitySpecifier[0],
              qty: item.qty,
              productSlug: item.product_slug,
              variantSku: item.variant_sku,
              title: product.title,
              price: parseFloat(selectedVariant.attributes.price),
              weight: selectedVariant.attributes.weight ? parseFloat(selectedVariant.attributes.weight) : 0,
              tax_id: product.tax_id,
              ignoreMiniumWeightRequirements: product.fields.ignoreMiniumWeightRequirements ? product.fields.ignoreMiniumWeightRequirements : false
            }
          })
          cartItems.forEach(item => this.props.addCartItem(item))
        }
      })
      .catch(err => {
        //Problem retrieving cart.. id
      })
      .finally(() => {
        this.setState({
          cartLoaded: true
        })
      })
  }

  onMenuClick = () => {
    document.querySelector('body').classList.toggle('show_nav_menu')
  }
  
  render() {
    let noticeArea = ''
    let linkText = '/'
    let timerActiveRoutes = 
    <Switch>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/confirmation">
        <Confirmation />
      </Route>
      <Route exact path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/information">
        <Information />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*">
        <Redirect to='/' />
      </Route>
    </Switch>
    if (this.props.timer.start) {
      linkText = '/menu'
    }
    if (this.props.timer && this.props.timer.start) {
      noticeArea = <div className="notice notice-fixed">
        <div className="fixed-area">
          <div className="split" style={{ outline: 'none' }}>
            <Pickup />
            <Timer />
          </div>
        </div>
      </div>
      timerActiveRoutes =
        <Switch>
          <Route exact path="/menu">
            <Menu />
          </Route>
          <Route exact path="/review">
            <Review />
          </Route>
          <Route exact path="/checkout">
            <Elements stripe={stripePromise} fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Alfa+Slab+One|Source+Sans+Pro:400,400i,700' }]}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <Checkout stripe={stripe} elements={elements} />
                )}
              </ElementsConsumer>
            </Elements>
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/confirmation">
            <Confirmation />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/information">
            <Information />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <Redirect to='/' />
          </Route>
        </Switch>
    }

    let appData = ''
    let navArea = <nav>
    <div className="page-container">
      <ul>
        <li><a href="https://franklinbbq.com">Franklinbbq.com</a></li>
        <li><a href="https://shop.franklinbbq.com">Shop</a></li>
      </ul>
    </div>
  </nav>
    if (this.props.isMenuActive) {
      navArea = ''
    }
    if (this.state.cartLoaded && this.state.settingsLoaded && this.state.taxLoaded) {
      appData =
        <Router history={history}>
          <header>
            <div className="fixed-area">
              <div className="page-container">
                <div className="logo" style={{ backgroundImage: `url(${logo})` }}>
                  <Link to={linkText}>Franklin</Link>
                </div>
                {this.props.timer.start ?
                  <div className="nav-menu-cart">
                    {this.props.cartItems.length > 0 ?
                      <div className="item-amount">
                        <span className="placeholder">{this.props.cartItems.length}</span>
                      </div> : ''}
                    <Link className='active no-outline' to={`/review`} />
                  </div> : ''}
                  <div className="nav-menu-toggle">
                    <button onClick={this.onMenuClick} className="open" aria-label="Open Menu"></button>
                    <button onClick={this.onMenuClick} className="close" aria-label="Close Menu"></button>
                  </div>
              </div>
            </div>
          </header>
          {navArea}
          {noticeArea}
          {timerActiveRoutes}
          <Footer />
        </Router>
    }
    return appData

  }
}

const mapStateToProps = state => {
  const timer = getTimer(state)
  const cartItems = getCartItems(state)
  const isMenuActiveLocal = isMenuActive(state)
  return {
    timer,
    cartItems,
    isMenuActive: isMenuActiveLocal
  }
}

export default connect(mapStateToProps, { setSettings, setTimer, setPickupDate, setPickupTime, setGratuity, setTaxCatagories, addCartItem, setNoSlotReservation })(App);
