import React from 'react'
import { connect } from 'react-redux'
import { getSettings } from '../redux/selectors'
import { createMarkup } from '../utilities'

class SiteBanner extends React.Component {

    state = {}

    render() {
        let bannerText = '';
        let noticeClass = ''
        if(this.props.noMargin) {
            noticeClass = ' notice-no-margin';
        }
        if(this.props.settings.enablePreorderSiteBannerAnnouncement && this.props.settings.preorderBannerText) {
            bannerText = <div className={`notice notice-info no-outline${noticeClass}`} dangerouslySetInnerHTML={createMarkup(this.props.settings.preorderBannerText)}></div>
        }
        return (
            bannerText
        )
    }


}

const mapStateToProps = state => {
    const settings = getSettings(state)
    return {
        settings
    }
}

export default connect(mapStateToProps)(SiteBanner)