import React from 'react'
import { connect } from 'react-redux'
import {
    Redirect
} from "react-router-dom"
import { getTimer } from '../redux/selectors'
import { clearCart } from '../redux/actions'


class Timer extends React.Component {
    state = {
        time: {},
        seconds: 0,
        isTimerRunning: false,
        sendHome: false
    }
    timer = 0
    hidden = ''
    visibilityChange = ''

    handleVisibilityChange = () => {
        if (document[this.hidden]) {
            clearInterval(this.timer)
            this.timer = 0
            this.setState({
                isTimerRunning: false
            })
        } else {
            this.setState({
                duration: new Date(this.props.end).getTime() - new Date().getTime()
            })
        }
    }

    secondsToTime(secs) {
        const hours = Math.floor(secs / (60 * 60))
        const divisor_for_minutes = secs % (60 * 60)
        const minutes = Math.floor(divisor_for_minutes / 60)

        const divisor_for_seconds = divisor_for_minutes % 60
        const seconds = Math.ceil(divisor_for_seconds)

        const obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        }

        return obj
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.duration !== prevState.duration) {
            this.setUpTimer()
        }
        if (this.state.duration === prevState.duration && this.state.seconds !== prevState.seconds && this.state.seconds > 0 && !this.state.isTimerRunning) {
            this.setState({
                isTimerRunning: true
            })
            this.startTimer()
        }
    }

    timerOverAction = () => {
        clearInterval(this.timer)
        this.props.clearCart()
        this.setState({
            isTimerRunning: false,
            sendHome: true
        })
    }

    setUpTimer = () => {
        if (this.state.duration <= 0) {
            this.timerOverAction()
        } else {
            let duration = Math.floor(this.state.duration / 1000);
            let timeLeftVar = this.secondsToTime(duration)
            this.setState({
                seconds: duration,
                time: timeLeftVar,
            })
        }
    }

    componentDidMount() {
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
            this.hidden = "hidden";
            this.visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            this.hidden = "msHidden";
            this.visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            this.hidden = "webkitHidden";
            this.visibilityChange = "webkitvisibilitychange";
        }
        document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
        this.setState({
            duration: new Date(this.props.end).getTime() - new Date().getTime()
        })
    }

    componentWillUnmount() {
        document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
        clearInterval(this.timer)
    }

    render() {
        if (this.state.sendHome) {
            return <Redirect to='/' />
        }
        const timer = this.state.isTimerRunning ? <span>Time remaining: <em>{("0" + this.state.time.m).slice(-2)}:{("0" + this.state.time.s).slice(-2)}</em></span> : ''
        return (
            timer
        )
    }

    startTimer = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000)
        }
    }

    countDown = () => {
        let seconds = this.state.seconds - 1
        this.setState({
            time: this.secondsToTime(seconds),
            seconds
        })

        // Check if we're at zero
        if (seconds === 0) {
            this.timerOverAction()
        }
    }
}


const mapStateToProps = state => {
    const { start, end, currentDate } = getTimer(state)
    return {
        start,
        end,
        currentDate
    }
}

export default connect(mapStateToProps, { clearCart })(Timer)