import React from 'react'
import { connect } from 'react-redux'
import { getCartItems, getCartSubtotal, getCartTaxes, isCartWeightMinimumValid, getWeightToMinimum } from '../redux/selectors'
import { removeCartItem, setIsMenuActive } from "../redux/actions"
import { formatPriceForTotal } from '../utilities'
import axios from 'axios'
import { API_URL, TAX_RATE } from '../constants'
import { Redirect } from 'react-router-dom'


class Cart extends React.Component {
    state = {
        toPayment: false,
        error: ''
    }
    
    removeItem(index, e) {
        // Get the item
        let item = this.props.items[index]
        axios.delete(`${API_URL}/cart/item?productSlug=${encodeURIComponent(item.productSlug)}&variantSku=${encodeURIComponent(item.variantSku)}`, {
            withCredentials: true
        })
        .then(response => {
            //let { data } = response
            this.props.removeCartItem({index})
            this.props.onCartItemRemoved()
        })
        e.preventDefault()
    }

    onReviewClick = () => {
        if(this.props.validCart) {
            this.props.setIsMenuActive(false)
            this.setState({
                goToReview: true
            })
        } else {
            this.setState({
                error: true
            })
        }
    }
    
    render() {
        if(this.state.goToReview) {
            return <Redirect push to="/review" />
        }
        const itemList = this.props.items.map((item, index) => {
            return (
                <tr key={index}>
                    <td className="no-outline">{item.qty} {item.quantitySpecifier} {item.title}</td>
                    <td><a href="#removeItem" aria-label={`Remove ${item.title}`} onClick={(e) => {this.removeItem(index,e)}} className="action-link">Remove</a></td>
                    <td>{formatPriceForTotal(item.qty * item.price)}</td>
                </tr>
            )
        })
        let errorNotice = (this.state.error && this.props.weightToMinimum > 0) ? <div className="notice notice-order no-outline">You need to add an additional <em>{this.props.weightToMinimum} lbs of meat</em> to place your order.</div> : ''
        const notice = itemList.length > 0 ? '' : <div className="notice notice-order"> Let’s get started! Select at least <em>{this.props.weightToMinimum} lbs of meat</em> to place your order.</div>
        return (
            <aside>
                <div className="order-summary">
                    <h3 className="no-outline">Order</h3>
                    {notice}
                    <table>         
                        <tbody>
                            {itemList}
                        </tbody>
                    </table>
                    <h3 className="no-outline">Estimated total</h3>
                    <table>         
                        <tbody>
                            <tr>
                                <td className="no-outline">Subtotal</td>
                                <td>{formatPriceForTotal(this.props.subtotal)}</td>
                            </tr>
                            <tr>
                                <td className="no-outline">Sales tax {TAX_RATE*100}%</td>
                                <td>{formatPriceForTotal(this.props.tax)}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td className="no-outline">{formatPriceForTotal(this.props.subtotal + this.props.tax)}</td>
                            </tr>
                        </tfoot>
                    </table>
                    {errorNotice}
                    <button onClick={this.onReviewClick} className={`${this.props.validCart ? '' : 'is_disabled'} button button-large button-page`}>Review order</button>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = state => {
    const items = getCartItems(state)
    const subtotal = getCartSubtotal(state)
    const tax = getCartTaxes(state)
    const validCart = isCartWeightMinimumValid(state)
    const weightToMinimum = getWeightToMinimum(state)

    return {
        items,
        subtotal,
        tax,
        validCart,
        weightToMinimum
    }
}

export default connect(mapStateToProps, {removeCartItem, setIsMenuActive})(Cart)