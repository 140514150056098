export const getCartState = store => store.cart

export const getCartItems = store =>
    getCartState(store) ? getCartState(store).items : []

export const getCartSubtotal = store => {
    const items = getCartItems(store)
    // Add all the items up
    return items.reduce((accumulator, item) => accumulator + (item.price * item.qty), 0)
}

export const getCartTaxes = store => {
    const taxCategories = getTaxCategories(store)
    const items = getCartItems(store)
    return items.reduce((accumulator, item) => {
        let taxCategory = taxCategories.find(category => category.id === item.tax_id)
        if(taxCategory) {
            return accumulator + (item.price * item.qty * taxCategory.rate)
        }
        return accumulator

    }, 0)
}

export const getCartWeight = store => {
    const items = getCartItems(store)
    return items.reduce((accumulator, item) => accumulator + (item.weight * item.qty), 0)
}

export const getCartNoSlotReservation = store => getCartState(store).noSlotReservationRequired

export const getWeightToMinimum = store => {
    const settings = getSettings(store)
    const weight = getCartWeight(store)
    return parseFloat(settings.minPoundsPerOrder) - weight
}

export const containsIgnoreWeight = store => {
    const items = getCartItems(store)
    return items.some(item => item.ignoreMiniumWeightRequirements)
}

export const isCartWeightMinimumValid = store => {
    const settings = getSettings(store)
    if(settings && settings.minPoundsPerOrder) {
        const weight = getCartWeight(store)
        return weight >= parseFloat(settings.minPoundsPerOrder) || containsIgnoreWeight(store)
    }
    return false
}

export const isCartWeightMaximumValid = store => {
    let settings = getSettings(store)
    if(settings && settings.maxPoundsPerOrder) {
        let weight = getCartWeight(store)
        return weight <= parseFloat(settings.maxPoundsPerOrder)
    }
    return false
}

export const getPickupDate = store => store.cart.pickup.date

export const getPickupTime = store => store.cart.pickup.time

export const getSettings = store => store.settings.settings

export const getTimer = store => store.cart.timer

export const getTaxCategories = store => store.cart.taxCategories

export const getGratuityPercentage = store => store.cart.gratuity

export const getGratuityAmount = store => getGratuityPercentage(store) * ( getCartSubtotal(store) + getCartTaxes(store))

export const isMenuActive = store => store.settings.isMenuActive